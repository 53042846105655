<template>
  <div>
    <div class="padding_top"></div>

    <b-container>
      <b-row align-h="center">
        <b-col cols="12" md="6">
          <b-row align-v="center" align-h="center">
            <p class="navbar__user_modal__label">Вход</p>
          </b-row>

          <b-row align-h="center" align-v="center">
            <p class="error_login" v-if="errorLogin">{{ errorLogin }}</p>
          </b-row>

          <div class="modal__center">
            <b-row
              class="modal__form_wrapper"
              align-h="center"
              align-v="center"
            >
              <b-col cols="11">
                <b-form class="modal__form" @submit="login">
                  <b-form-group label="Email или логин">
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      type="text"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group label="Пароль">
                    <b-form-input
                      id="password"
                      type="password"
                      v-model="form.password"
                      required
                    ></b-form-input>
                  </b-form-group>

                  <b-button
                    type="submit"
                    class="main__button modal_form__buttom"
                    :disabled="loading"
                  >
                    Вход
                  </b-button>
                </b-form>
              </b-col>
            </b-row>

            <b-row align-v="center" align-h="center">
              <button
                @click="to('PasswordReset')"
                class="modal__forgot_password"
              >
                Забыли пароль?
              </button>
            </b-row>
          </div>

          <b-row align-v="center" align-h="center">
            <b-col cols="11">
              <b-row align-h="center">
                <div class="modal_line"></div>
              </b-row>
              <b-row align-h="center">
                <button
                  @click="to('Register')"
                  class="outline__button modal_form__buttom"
                  type="button"
                >
                  Создать аккаунт
                </button>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      errorLogin: null,
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    to(name) {
      if (this.$route.name != name) this.$router.push({ name: name });

      this.$bvModal.hide("login");
    },
    async login(e) {
      e.preventDefault();
      this.errorLogin = null;

      try {
        this.$store.commit("SET_LOADING", true);

        await this.$store.dispatch("login", this.form);
        this.loginButtonDisabled = false;
        this.$router.push({ name: "Home" });

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);

        let errMsg = e;

        if (e.response) {
          errMsg = e.response.data.message;
        }

        this.$bvToast.toast(`${errMsg}`, {
          autoHideDelay: 7000,
          noAutoHide: false,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.error_login {
  color: $accent;
}
</style>
